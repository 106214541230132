import request from '../request'

export function fetchList(data) {
    return request({
        url: '/project-service/contractRegister/list',
        method: 'post',
        data,
    })
}

// 合同登记审批
export function add(data) {
    return request({
        url: '/project-service/contractRegister/save',
        method: 'POST',
        data
    })
}

// 合同登记审批重新提交
export function edit(data) {
    return request({
        url: '/project-service/contractRegister/modify',
        method: 'POST',
        data
    })
}

export function changeStatus(data) {
    return request({
        url: '/project-service/contractRegister/modifyStatus',
        method: 'post',
        data,
    })
}

// 获取关联投标及项目委托
export function fetchInfo(params) {
    return request({
        url: '/project-service/contractRegister/projectApproval/list',
        params,
    })
}

// 获取关联合同
export function fetchProject(params) {
    return request({
        url: '/project-service/contractRegister/prjInfoSetUpProject/list',
        params
    })
}

// 获取自动增加的设计编号
export function fetchCode(params) {
    return request({
        url: '/project-service/contractRegister/getContractCode',
        params,
    })
}

// 获取合同登记详情
export function fetchDetail({ id }) {
    return request({
        url: 'project-service/contractRegister/query/' + id
    })
}

// 修改合同登记（签章），不审批
export function update(data) {
    return request({
        url: '/project-service/contractRegister/archive',
        method: 'post',
        data
    })
}