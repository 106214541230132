<template>
  <a-modal :visible="visible" title="高级搜索" @cancel="cancel" :footer="null" width="800px">
    <a-form :form="form" layout="inline" @submit="handleSubmit">
      <a-form-item>
        <a-input v-decorator="['code']" style="width: 240px" placeholder="设计编号" />
      </a-form-item>
      <a-form-item>
        <a-input v-decorator="['name']" style="width: 240px" placeholder="合同名称" />
      </a-form-item>
      <a-form-item>
        <a-select v-decorator="['contractType']" style="width: 240px" placeholder="合同类型">
          <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-cascader style="width: 240px" placeholder="所属区域" changeOnSelect v-decorator="['area']" :options="areaList"
          :fieldNames="{
                        label: 'areaName',
                        value: 'key',
                        children: 'children',
                      }" />
      </a-form-item>

      <a-form-item>
        <a-date-picker v-decorator="['belongYear']" mode="year" placeholder="年份" format="YYYY" style="width: 240px"
          :open="open" @openChange="openChange" @panelChange="panelChange" />
      </a-form-item>

      <a-form-item>
        <a-select v-decorator="['phase']" mode="multiple" style="width: 240px" placeholder="阶段">
          <a-select-option v-for="item in phaseList" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-cascader v-decorator="['major']" style="width: 240px" placeholder="专业" changeOnSelect :options="majorList"
          :fieldNames="{
                        label: 'name',
                        value: 'value',
                        children: 'children',
                      }" />
      </a-form-item>

      <div class="title">合同金额</div>

      <a-form-item>
        <a-select v-decorator="['status']" style="width: 240px" placeholder="合同状态">
          <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-input-number :formatter="$inputNumberFormat" v-decorator="['investmentMin']" style="width: 240px"
          placeholder="最小合同额" />
      </a-form-item>

      <a-form-item>
        <a-input-number :formatter="$inputNumberFormat" v-decorator="['investmentMax']" style="width: 240px"
          placeholder="最大合同额" />
      </a-form-item>

      <a-form-item>
        <a-input v-decorator="['partyZname']" style="width: 240px" placeholder="业主方" />
      </a-form-item>

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>


<script>
import area from "@/mixins/area";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  mixins: [area],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    searchValues: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      open: false, // 年份选择器显示隐藏
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        const {
          code,
          name,
          contractType,
          areaId,
          subAreaId,
          l3AreaId,
          belongYear,
          phase,
          major,
          status,
          investmentMin,
          investmentMax,
          partyZname,
        } = this.searchValues;

        let area = [];
        if (areaId) {
          area.push(areaId);
        }
        if (subAreaId) {
          area.push(subAreaId);
        }
        if (l3AreaId) {
          area.push(l3AreaId);
        }

        let majorIdList = [];
        if (Array.isArray(major) && major.length > 0) {
          if (major[0].major) {
            majorIdList.push(major[0].major);
          }
          if (major[0].majorSub) {
            majorIdList.push(major[0].majorSub);
          }
          if (major[0].scale) {
            majorIdList.push(major[0].scale);
          }
        }

        this.form.setFieldsValue({
          code,
          name,
          contractType,
          belongYear: belongYear ? moment(belongYear) : undefined,
          area,
          phase: phase ? phase.split(",") : [],
          major: majorIdList,
          status,
          investmentMin,
          investmentMax,
          partyZname,
        });
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("contractType");
    },

    phaseList() {
      return this.findDataDict("phase");
    },

    majorList() {
      return this.findDataDict("contract_scale");
    },

    statusList() {
      return this.findDataDict("contractRegisterStatus");
    },
  },

  methods: {
    // 年份选择器
    panelChange(value) {
      // this.form.belongYear = value;
      this.form.setFieldsValue({
        belongYear: value,
      });
      this.open = false;
    },
    // 年份选择器面板显示隐藏
    openChange(value) {
      this.open = value;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          let areaId, subAreaId, l3AreaId;
          if (Array.isArray(values.area)) {
            areaId = values.area[0];
            subAreaId = values.area[1];
            l3AreaId = values.area[2];
          }

          let majorObj = {};
          if (Array.isArray(values.major)) {
            majorObj.major = values.major[0];
            majorObj.majorSub = values.major[1];
            majorObj.scale = values.major[2];
          }

          const params = {
            ...values,
            belongYear: values.belongYear
              ? values.belongYear.format("YYYY")
              : undefined,
            major: majorObj.major ? [majorObj] : undefined,
            phase: Array.isArray(values.phase)
              ? values.phase.join(",")
              : undefined,
            areaId,
            subAreaId,
            l3AreaId,
            area: undefined,
          };

          console.log("params", params);
          this.$emit("search", params);
          this.cancel();
        }
      });
    },

    cancel() {
      this.$emit("setVisible");
    },
  },
};
</script>


<style lang="less" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  padding: 12px 0;
}
</style>